<template>
  <div
    v-if="document.id"
    class="d-flex flex-column flex-grow-1"
  >
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      :comment="true"
      :hasComment="!!(document.comment || '').length"
      :showSave="!readonly"
      :showCancel="!readonly"
      v-on="$listeners"
      no-history
      :show-delete="userIsMasterAdmin"
      :delete-document="deleteDocument"
      :special-actions="specialActions"
      :labels.sync="document.labels"
      show-labels
    />
    <document-in-tab>
      <v-form
        v-model="form"
        ref="form1"
        :disabled="readonly"
      >
        <comment
          class="comment mb-6"
          :disabled="readonly"
          :text.sync="document.comment"
          :showComment="tab.showComment"
        />
        <identification
          class="mb-3"
          :account.sync="document"
        />
        <custom-fields
          v-if="document.customFields.length"
          class="my-3"
          :custom-fields.sync="document.customFields"
        />
        <account-address
          class="my-3"
          :account.sync="document"
        />
        <risk
          class="my-3"
          :account-id="id"
          :credit-limit.sync="document.creditLimit"
          :currency="document.currency"
          @update:risk-category-type="computedRiskCategoryType = $event"
        />
        <groupama
          class="my-3"
          v-if="computedGroupamaIsActive"
          v-model="groupama"
        />
        <configuration
          class="mt-3"
          :account.sync="document"
          :import-settings="importSettings"
          :risk-category-type="computedRiskCategoryType"
        />
      </v-form>
    </document-in-tab>
    <v-dialog
      v-model="showCascadeDeleteConfirm"
      max-width=500
    >
      <v-card>
        <v-card-title>{{$t('t.Warning')}}</v-card-title>
        <v-card-text>
          <span>{{$t('t.ConfirmDeleteAllRelatedDataToAccount')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="cascadeDeleteRunning"
            @click="doCascadeDelete()"
          >{{$t('t.Ok')}}</v-btn>
          <v-btn
            color="primary"
            @click="showCascadeDeleteConfirm = false"
          >{{$t('t.Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'
import customFieldsController from '@/customFieldsController'

export default {
  mixins: [DocumentEditor],
  components: {
    AccountAddress: () => import('./address'),
    Comment: () => import('@/components/comment'),
    Configuration: () => import('./configuration'),
    CustomFields: () => import('./custom-fields'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Groupama: () => import('./groupama'),
    Identification: () => import('./identification'),
    Risk: () => import('./risk')
  },
  computed: {
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    specialActions () {
      if (this.documentIsNew || !this.userIsMasterAdmin) { return undefined }
      return [{ icon: this.$icon('i.Delete'), text: this.$t('t.DeleteAllRelatedDataToAccount'), callback: this.confirmDeleteCascade }]
    },
    cacheType () {
      return DocumentEditor.CacheType.AccountDetail
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('AccountEdit')
    },
    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: v })
      }
    },
    computedGroupamaIsActive () {
      return this.$store?.getters?.currentUser?.settings?.groupamaIsActive
    },
    groupama: {
      get () {
        return this.document?.groupama
      },
      set (v) {
        this.document.groupama = v
      }
    },
    computedRiskCategoryType: {
      get () {
        return this.dataRiskCategoryType
      },
      set (v) {
        this.dataRiskCategoryType = v
      }
    }
  },
  data () {
    return {
      cascadeDeleteRunning: false,
      showCascadeDeleteConfirm: false,
      dataRiskCategoryType: null,
      id: this.$route.params.id,
      importSettings: {
        workQueueAllocationAutomatic: false
      }
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (!this.document.id) { return }
      if (this.document.comment?.length) {
        this.$emit('document-comment', { tab: this.tab, value: true })
      }
    },
    setCreditLimit (v) {
      this.document.creditLimit = v
    },
    async save () {
      const req = [this.$http().put(`/core/v6/accounts/${this.id}`, this.documentToJSON())]
      if (this.document?.groupama) {
        req.push(
          this.$http().post(
            `/core/v6/accounts/${this.id}/groupama`,
            {
              creditDirection: this.document.groupama.creditDirection,
              endCreditDirection: this.document.groupama.endCreditDirection,
              gacNumber: this.document.groupama.gacNumber,
              startCreditDirection: this.document.groupama.startCreditDirection
            }
          )
        )
      }
      return await Promise.all(req)
    },
    confirmDeleteCascade () {
      this.showCascadeDeleteConfirm = true
    },
    async doCascadeDelete () {
      this.cascadeDeleteRunning = true
      this.$http().delete(`/core/v6/accounts/${this.id}`, { data: { force: true } })
        .then(async () => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.CascadeDeleteConfirmation'))
          this.$router.replace('/')
        })
        .catch(e => {
          this.$store.dispatch(
            'showErrorSnackbar',
            e.response?.data?.message
          )
        })
        .finally(() => {
          this.cascadeDeleteRunning = false
        })
    },
    async deleteDocument () {
      this.$http().delete(`/core/v6/accounts/${this.id}`, { data: { force: false } })
        .then(async () => this.$router.replace('/'))
        .catch(e => {
          this.$store.dispatch(
            'showErrorSnackbar',
            e.response?.data?.message
          )
        })
    },
    documentToJSON () {
      return Object.assign(
        {
          automaticWorkqueueAssign: this.document.automaticWorkqueueAssign,
          businessDivisionId: this.document.businessDivisionId,
          collectionSegmentId: this.document.collectionSegmentId,
          comment: this.document.comment,
          countryIso2: this.document.countryIso2,
          creditHoldReasonId: this.document.creditHoldReasonId,
          creditLimitAc: this.document.creditLimit,
          dunningAddress1: this.document.dunningAddress?.address1,
          dunningAddress2: this.document.dunningAddress?.address2,
          dunningAddress3: this.document.dunningAddress?.address3,
          dunningBlocked: this.document.dunningBlocked,
          dunningCity: this.document.dunningAddress?.city,
          dunningCountry: this.document.dunningAddress?.country,
          dunningPostalCode: this.document.dunningAddress?.postalCode,
          dunningStateProvince: this.document.dunningAddress?.stateProvince,
          excludeFromDso: this.document.excludeFromDso,
          externalRiskNumber: this.document.externalRiskNumber,
          id: this.document.id,
          informWorkQueueUsers: this.document.informWorkQueueUsers,
          mainSalesRepId: this.document.mainSalesRepId || null,
          name: this.document.name,
          onOrderHold: Boolean(this.document.creditHoldReasonId),
          riskMonitored: this.document.riskMonitored,
          secondSalesRepId: this.document.secondSalesRepId || null,
          workQueueId: this.document.workQueueId
        },
        customFieldsController.convertForJSON(this.document.customFields))
    }
  },
  watch: {
    document: function () {
      this.$set(this.tab, 'number', this.document.name)
      this.$set(this.tab, 'documentId', this.document.id)
      this.$waitFor(() => this.$refs.form1).then(form => setTimeout(form?.validate, 1000))
    }
  },
  props: {
    tab: Object
  }
}
</script>
